import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Paper, Typography, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Field, SetNewPasswordForm } from "../components";
import { recaptchaSubmit, jsonRequest } from "../utils";
import { SiteContext } from "../layouts/Main";

import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  login: {
    padding: theme.spacing(4, 0),
    maxWidth: 560,
    margin: "0 auto",
  },
  title: {
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(3),
    "& > *:not(:first-child)": {
      marginTop: `${theme.spacing(2)}px !important`,
      width: "100%",
    },
  },
  links: {
    textAlign: "center",
  },
}));

const validationSchemaRequest = yup.object({
  mail: yup.string().required("Email is required").email("Enter a valid email address"),
});

export default function ResetPasswordPage() {
  const classes = useStyles();
  const { drupalUrl, userData } = React.useContext(SiteContext);
  const [ok, setOk] = React.useState(false);
  let userAttrCheck = userData ? userData.attributes.mail : "";
  const [mail, setMail] = React.useState(
  userData !== null && userAttrCheck ? userAttrCheck : ""
  );
  const [error, setError] = React.useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmitRequest = async (values, setSubmitting, setError) => {
    setSubmitting(true);
    try {
      const response = await jsonRequest(
        drupalUrl,
        "user/lost-password?_format=json",
        "POST",
        values
      );
      if (response.ok) {
        if (values.mail) {
          setMail(values.mail);
        }
        setOk(true);
      } else {
        throw new Error("Unable to send reset password email");
      }
    } catch (err) {
      setSubmitting(false);
      setError(err.message);
    }
  };

  const requestForm = useFormik({
    validationSchemaRequest,
    initialValues: {
      mail,
    },
    onSubmit: async (values, { setSubmitting }) => {
      setError(null);
      const valid = await recaptchaSubmit(executeRecaptcha, drupalUrl);
      if (!valid) {
        setError("Failed Recaptcha", drupalUrl);
      } else {
        await handleSubmitRequest(values, setSubmitting, setError);
      }
    },
  });

  if (!ok) {
    return (
      <div className={classes.login}>
        <Paper
          className={classes.paper}
          component="form"
          onSubmit={requestForm.handleSubmit}
        >
          {error ? (
            <Alert severity="error">{error}</Alert>
          ) : (
            <Typography>
              Enter email associated with the account you would like to password reset
            </Typography>
          )}
          <Field
            form={requestForm}
            label="Email Address:"
            name="mail"
            variant="outlined"
            type="email"
          />
          <Button
            variant="contained"
            color="secondary"
            size="large"
            type="submit"
            disabled={requestForm.isSubmitting}
          >
            Reset Password
          </Button>
        </Paper>
      </div>
    );
  } else {
    return <SetNewPasswordForm initialMail={mail} />;
  }
}
